<template>
    <div>
        <Cropper ref="cropper" v-model:cropped="cropped" />

        <input
            type="file"
            ref="file"
            @input="change"
            :accept="formats"
            class="hidden"
        />

        <div
            v-if="fileContent === null"
            class="image-placeholder centered-content"
            @click="$refs.file.click()"
        >
            <Icon name="plus" size="2x" />
        </div>
        <div v-else>
            <img class="image" :src="fileContent" @click="$refs.file.click()" />
        </div>
    </div>
</template>

<script>
import Cropper from "../images/Cropper.vue";

export default {
    name: "file-input",
    components: {
        Cropper,
    },
    props: {
        formats: {
            type: String,
            default: ".jpg,.jpeg,.png",
        },
    },
    emits: ["update:file"],
    data() {
        return {
            cropped: null,
        };
    },
    computed: {
        fileContent() {
            if (this.cropped === null) return null;
            return URL.createObjectURL(this.cropped);
        },
    },
    watch: {
        cropped(cropped) {
            this.$emit("update:file", cropped);
        },
    },
    methods: {
        change(event) {
            const { files } = event.target;
            let file = files[0];

            this.$refs.cropper.load(file);
            this.$refs.cropper.open();

            this.$refs.file.value = "";
        },
    },
};
</script>

<style lang="scss" scoped>
$size: 200px;

.hidden {
    display: none;
}

.image {
    width: $size;
    height: $size;

    cursor: pointer;

    border: 2px solid lightgray;
    border-radius: 10px;

    box-shadow: 0rem 0rem 0.75rem gray;
}

.image-placeholder {
    width: $size;
    height: $size;

    cursor: pointer;

    border: 2px dashed black;
    border-radius: 10px;

    background-color: rgb(240, 240, 240);

    &:hover {
        background-color: rgb(230, 230, 230);
    }
}
</style>