import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faCircleUser, faPersonRunning, faLessThanEqual, faFutbol, faChevronRight,
    faChevronLeft, faRightFromBracket, faEye, faEyeSlash, faUser, faMagnifyingGlass,
    faGear, faGlobe, faD, faTrashCan, faShareNodes, faFloppyDisk, faArrowUp,
    faArrowDown, faEarthEurope, faEllipsis, faVideo, faImage, faList, faTable,
    faGrip, faSliders, faX, faCalendarDays, faTv, faVideoCamera, faScissors,
    faICursor, faPencil, faPlus, fa0, faAnglesDown, faAngleDown, faEquals,
    faAngleUp, faAnglesUp, faCheck, faLock, faLockOpen, faRotate, faCaretUp,
    faCaretDown, faClockRotateLeft, faChartLine, faRightLeft, faStar,
    faClipboard, faPeopleGroup, faThumbTack
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faCircleUser, faPersonRunning, faLessThanEqual, faFutbol, faChevronRight,
    faChevronLeft, faRightFromBracket, faEye, faEyeSlash, faUser, faMagnifyingGlass,
    faGear, faGlobe, faD, faTrashCan, faShareNodes, faFloppyDisk, faArrowUp,
    faArrowDown, faEarthEurope, faEllipsis, faVideo, faImage, faList, faTable,
    faGrip, faSliders, faX, faCalendarDays, faTv, faVideoCamera, faScissors,
    faICursor, faPencil, faPlus, fa0, faAnglesDown, faAngleDown, faEquals,
    faAngleUp, faAnglesUp, faCheck, faLock, faLockOpen, faRotate, faCaretUp,
    faCaretDown, faClockRotateLeft, faChartLine, faRightLeft, faStar,
    faClipboard, faPeopleGroup, faThumbTack);

import {
    faFacebook, faSquareXTwitter, faInstagram, faYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(faFacebook, faSquareXTwitter, faInstagram, faYoutube);

function load(app) {
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.component('font-awesome-layers', FontAwesomeLayers);
}

export default {
    load
}